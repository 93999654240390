const run = function () {
    const img = document.querySelector('.member-photo')
    document.querySelectorAll('.staff-member').forEach(node => {
        node.addEventListener('mouseenter',(event)=>{
            img.src = event.target.querySelector('img').src
        })
        node.addEventListener('mouseleave',(event)=>{
            img.src = '/empty.svg'
        })
    })
}


const StaffHover = {
    start: run
}

export { StaffHover as default };