import { createApp } from 'vue';
import megaMenu from '@/components/mega_menu/index.vue';
import megaMenuMobile from '@/components/mega_menu_mobile/index.vue';
import {tns} from 'tiny-slider';
// import simpleParallax from "simple-parallax-js/vanilla";
// import LocomotiveScroll from 'locomotive-scroll';
import Rails from "@rails/ujs"
import Painter from "@/stuff/char_painter.js";
import StaffHover from "@/stuff/staff_hover.js";
import IMask from 'imask';
import scrollSpy from '@sidsbrmnn/scrollspy'

import {params} from '../stuff/map_params.js'

if (document.querySelector('#map')) {
    initMap();
}


async function initMap() {
    await ymaps3.ready;
    ymaps3.import.registerCdn('https://cdn.jsdelivr.net/npm/{package}', '@yandex/ymaps3-default-ui-theme@0.0.2')
    const {YMap, YMapDefaultSchemeLayer,YMapDefaultFeaturesLayer,YMapCollection,YMapControls,YMapMarker} = ymaps3;
    const {YMapZoomControl} = await ymaps3.import('@yandex/ymaps3-controls@0.0.1');
    // const {YMapDefaultMarker} = await ymaps3.import('@yandex/ymaps3-default-ui-theme');

    const markerElement = document.createElement('div');
    markerElement.id = 'marker-wrapper';
    markerElement.innerHTML = '<a id="custom-marker" href="https://yandex.ru/maps/?rtext=~55.749554,37.536758" target="_blank"><img src="/img/map-marker.svg?v=3" /></a>'

    const marker2 = new YMapMarker(
        {
            coordinates: [37.536758,55.749554],
            mapFollowsOnDrag: true
        },
        markerElement
    );

    const map = new YMap(
        document.getElementById('mapp'),
        {
            location: {
                center: window.screen.width > 500 ? [37.530758,55.749554] : [37.536758,55.749554],
                zoom: 15
            },
            behaviors: ['drag', 'pinchZoom', 'mouseTilt']
        },
    );

    const collection = new YMapCollection();
    map.addChild(new YMapDefaultSchemeLayer({customization:params}))
    map.addChild(new YMapDefaultFeaturesLayer({zIndex: 1800}))

    map.addChild(collection);
    map.addChild(new YMapControls({position: 'right'}).addChild(new YMapZoomControl({})));
    map.addChild(marker2);
}


document.addEventListener("DOMContentLoaded", (event) => {
    for (let i = 1; i < 13; i++) {
        Painter.start(`#animate${i > 1 ? i : ''}`)
    }

    document.querySelectorAll('.animate-header').forEach(node => {
        Painter.start(`#${node.id}`)
    })

    const mega_menu = document.getElementById('v-mega-menu');
    if (mega_menu) {
        const app = createApp(megaMenu,{});
        app.mount(mega_menu);
    }

    const mega_menu_mobile = document.getElementById('v-mega-menu-mobile');
    if (mega_menu_mobile) {
        const app = createApp(megaMenuMobile,{});
        app.mount(mega_menu_mobile);
    }

})



document.addEventListener("DOMContentLoaded", (event) => {
    //мобильное меню
    const m_togglers =  document.querySelectorAll('.mobile-menu-toggler')
    m_togglers.forEach( el => {
        el.addEventListener('click', () => {
            const menu = document.querySelector('.mobile-menu')
            if (menu) {
                if (menu.classList.contains('opened')) {
                    menu.classList.remove('opened')
                } else {
                    menu.classList.add('opened')
                }
            }
        })
    })
})



const breakpoints = {
    a: { 1441: { spaceBetween: 40, slidesPerView: 2 }, 980: { spaceBetween: 30, slidesPerView: 2 }, 430: { spaceBetween: 20, slidesPerView: 1 }, 0: { spaceBetween: 20, slidesPerView: 1 } },
    b: { 1441: { spaceBetween: 20, slidesPerView: 3 }, 980: { spaceBetween: 20, slidesPerView: 3 }, 430: { spaceBetween: 20, slidesPerView: 1 }, 0: { spaceBetween: 20, slidesPerView: 1 } },
    c: { 1441: { spaceBetween: 40, slidesPerView: 3 }, 980: { spaceBetween: 30, slidesPerView: 3 }, 430: { spaceBetween: 20, slidesPerView: 1 }, 0: { spaceBetween: 20, slidesPerView: 1 } },
    d: { 1441: { spaceBetween: 0, slidesPerView: 1 },  980: { spaceBetween: 0, slidesPerView: 1 }, 430: { spaceBetween: 0, slidesPerView: 1 }, 0: { spaceBetween: 0, slidesPerView: 1 } },
}
document.addEventListener("DOMContentLoaded", (event) => {



    if (document.querySelector('header')) {
        if (!document.querySelector('header').classList.contains('skip-scroll')) {
            window.addEventListener('scroll', () => {
                document.querySelector('header').classList.toggle('header-scrolled', window.scrollY > window.innerHeight);
            });
            if (document.querySelector('.mobile-header')) {
                window.addEventListener('scroll', () => {
                    document.querySelector('.mobile-header').classList.toggle('mobile-header-scrolled', window.scrollY > window.innerHeight);
                });
            }
        }

    }

    if (document.querySelector('.support-btn')) {
        window.addEventListener('scroll', () => {
            document.querySelector('.support-btn').classList.toggle('support-btn-scrolled', window.scrollY > 80);
        });
    }



    // // SWIPER
    //
    //
    // const swipers = document.querySelectorAll(".swiper")
    // swipers.forEach((swiper) => {
    //     let slider = new Swiper(swiper, {
    //         // Optional parameters
    //         direction: 'horizontal',
    //         pagination: {
    //             el: '.swiper-pagination',
    //             type: 'bullets',
    //
    //         },
    //         breakpoints: breakpoints[swiper.dataset.breakpoint]
    //     });
    // });


    //  FAQ

    document.querySelectorAll(".question-header").forEach((el) => {
        el.addEventListener('click', (event) => {
            let answer = event.target.closest('.question').querySelector('.answer')
            let shevron = event.target.closest('.question').querySelector('.faq-shevron')
            answer.classList.toggle('hidden')
            shevron.classList.toggle('rotated')
        })
    })

// /POPUP

    const popupform = document.querySelector(".popup-form");
    // const connectbtn = document.querySelector('.connect')
    const applicationbtn = document.querySelector(".header-btn1");
    const individualorder = document.querySelector('.individual-order')
    const popupclose = document.querySelector(".popup-close");

    if (popupform) {
        applicationbtn.addEventListener("click", () => popupform.classList.remove("hidden"));
        if (individualorder) {
            individualorder.addEventListener("click", () => popupform.classList.remove("hidden"));
        }

        popupclose.addEventListener("click", () => {
            popupform.classList.add("hidden")
            document.querySelector('#msg-frm').classList.add('hidden')
            document.querySelector('.popup-wrapper .text-wrapper').classList.remove('hidden')
            document.querySelector('#request-form').reset()
            document.querySelector('#request-form').classList.remove('hidden')
        })
    }

    document.querySelectorAll('.popup-opener').forEach(node => {
        node.addEventListener("click", () => popupform.classList.remove("hidden"));
    })

})

document.addEventListener("DOMContentLoaded", (event) => {

    if (document.querySelector('#welcome-slider')) {
        tns({
            container: '#welcome-slider',
            'mouseDrag': true,
            'loop': true,
            'nav': true,
            'controls': false,
            // 'swipeAngle': 40,
            'navPosition': 'bottom',
            'autoplay': true,
            'swipeAngle': false,
            'autoplayTimeout': 5000,
            "autoplayButton": "#customize-toggle"
        });
    }


    if (document.querySelector('#reviews-slider')) {
        tns({
            container: '#reviews-slider',
            'mouseDrag': true,
            'loop': true,
            'controls': true,
            'nav': false,
            'swipeAngle': 40,
            'gutter':  20,
            'controlsContainer': '#reviews-controls',
            responsive: {
                768: {'items': 1},
                769: {'items': 2},
                981: {'items': 3},
            }
        });
    }

    document.querySelectorAll('.my-slider-a').forEach(node => {
        tns({
            container: node,
            'mouseDrag': true,
            'autoWidth': true,
            'loop': false,
            'controls': false,
            'nav': false,
            'swipeAngle': 40,
            responsive: {
                    0: {'items': 1.3,"gutter": 10, 'edgePadding': 10},
                430: {'items': 1.3,"gutter": 20, 'edgePadding': 10},
                980: {'items': 2.3,"gutter": 20, 'edgePadding': 20},
                1441: {'items': 2.3,"gutter":  20, 'edgePadding': 60},
                1601: {'items': 2.3,"gutter":  30, 'edgePadding': 60},
            }
        });
    })
    document.querySelectorAll('.my-slider-b').forEach(node => {
        tns({
            container: node,
            'mouseDrag': true,
            // 'autoWidth': true,
            'loop': false,
            'controls': true,
            'nav': true,
            'swipeAngle': 40,
            'items': 3,
            "slideBy": "page",
            controlsPosition: 'bottom',
            navPosition: 'bottom',
            // controlsText: ['<', '>'],
            controlsContainer: '#customize-controls',
            responsive: {
                0: {'items': 1,"gutter": 0},
                430: {'items': 1,"gutter": 0},
                980: {'items': 2,"gutter": 40},
                1441: {'items': 3,"gutter": 80},
            }
        });
    })


    let connector = document.querySelector('#connector')
    if (connector) {
        connector.addEventListener('click',(event) => {
            connector.classList.add('transparent')
            document.querySelector('.connection-list').classList.remove('transparent')
        })
    }


})

document.addEventListener('DOMContentLoaded',()=>{
    document.querySelectorAll('.catalog-opener').forEach((el)=>{
        el.addEventListener('click',()=>{
            if (document.querySelector('.btn-cars').checkVisibility()) {
                document.querySelector('.btn-cars').click()
            } else {
                document.querySelector('.mobile-menu').classList.add('opened')
                document.querySelector('.mobile-menu-opener').click()
            }
        })
    })

    document.querySelectorAll('.goto').forEach(el => {
        el.addEventListener('click',()=>{
            const target = el.dataset.target
            if (target) {
                let offset = -130;
                const element = document.getElementById(target);
                if (el.dataset.skip === 'padding') {
                    console.log(offset)
                    offset += parseInt(window.getComputedStyle(element, null).getPropertyValue('padding-top'))
                    console.log(offset)
                }
                const y = element.getBoundingClientRect().top + window.scrollY + offset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }
        })
    })

    const url = new URL(window.location.href);
    if (url.hash.length > 0) {
        const offset = -130;
        const element = document.querySelector(url.hash);
        if (element) {
            setTimeout(() => {
                const y = element.getBoundingClientRect().top + window.scrollY + offset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }, "500");

        }
    }

    if (document.getElementById('cookie-closer')) {
        document.getElementById('cookie-closer').addEventListener('click',()=>{
            document.querySelector('.cookies-warning-container').remove();
            document.cookie = "hideCookieWarning=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
        })
    }

    document.querySelectorAll('.phone-mask').forEach(el => {
        let maskOptions = {
            mask: '+{7} (000) 000-00-00'
        };
        IMask(el, maskOptions);
    })

    document.querySelectorAll('.catalog-link').forEach(el => {
        el.addEventListener('click',(event)=>{
            window.quick_catalog = event.target.closest('.catalog-link').dataset.catalog
            if (document.querySelector('.btn-cars').checkVisibility()) {
                document.querySelector('.btn-cars').click()
            } else {
                document.querySelector('.mobile-menu').classList.add('opened')
                document.querySelector('.mobile-menu-opener').click()
            }
        })
    })

    document.querySelectorAll(".feature-short").forEach((el) => {
        el.addEventListener('click', (event) => {
            let desc = event.target.closest('.feature').querySelector('.feature-description')
            let shevron = event.target.closest('.feature').querySelector('.faq-shevron')
            desc.classList.toggle('hidden')
            shevron.classList.toggle('rotated')
        })
    })

    if (document.querySelector('.dealers-features')) {
        const options = {
            sectionSelector: '.scroll-section',  // Query selector to your sections
            targetSelector: 'a.feature-title', // Query selector to your elements that will be added `active` class
            offset: -900,                  // Menu item will active before scroll to a matched section 100px
            activeClass: 'active'
        }

        scrollSpy('#feature-titles', options)
    }


    document.querySelectorAll('.copier').forEach( el => {
        el.addEventListener('click', (event) => {
            navigator.clipboard.writeText(event.target.dataset.copy).then(function() {
                const span = document.createElement("span");
                span.classList.add('copy-done')
                span.innerHTML = 'Текст скопирован <img src="/img/done.svg">'
                event.target.closest('.info-detail').appendChild(span)
                setTimeout(function(){span.remove()},500);
            })
        })
    })

    StaffHover.start()

    if (document.querySelector('.curtain')) {
        document.querySelector('.curtain').classList.add('closed')
    }

})

Rails.start()