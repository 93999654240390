import SplitType from 'split-type'
import { gsap } from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger)
import debounce from 'debounce';

function paint(node) {
    const text = new SplitType(node)
    gsap.fromTo(`${node} .char`, {
        color: "#DADADA"
    }, {
        duration: 1.5,
        color: "#222",
        stagger: .05,
        ease: "power4.out",
        scrollTrigger: {
            scrub: 2,
            trigger: `${node}`,
            start: "top 90%",
            end: "bottom 50%"
        }
    })
}

const run = function run(node) {
    if (document.querySelector(node)) {
      paint(node)
      window.onresize = debounce(()=>{
          const el = document.querySelector(node)
          const txt = el.innerText;
          el.innerHTML = txt
          paint(node)
      }, 500);
    }
}

const Painter = {
    start: run
}


export { Painter as default };